import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { AuthService } from '../authentication/services/auth.service';
import { catchError, map, Observable, throwError, from } from 'rxjs';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.authService.supabaseAccessToken;
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((httpErrorResponse: HttpErrorResponse, _: Observable<HttpEvent<any>>) => {
        if (httpErrorResponse.status === HttpStatusCode.Unauthorized) {
          // Swal.fire({
          //   icon: 'error',
          //   title: 'Erreur',
          //   text: 'Veuillez vous déconnecter et vous reconnecter',
          // });

          from(this.authService.signOutAndNavigate());
        }
        return throwError(() => httpErrorResponse);
      })
    );
  }
}
